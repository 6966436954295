import Vue from 'vue';
import Component from 'vue-class-component';
import LogoAlt from '@/modules/landing/components/logo-alt/LogoAlt.vue';
// eslint-disable-next-line import/extensions
import FacebookIcon from '@/assets/images/landing/facebook.svg?inline';
// eslint-disable-next-line import/extensions
import TwitterIcon from '@/assets/images/landing/twitter.svg?inline';
// eslint-disable-next-line import/extensions
import LinkedInIcon from '@/assets/images/landing/linkedin.svg?inline';
// eslint-disable-next-line import/extensions
import InstagramIcon from '@/assets/images/landing/instagram.svg?inline';
import { email, phone } from '../../../../../whitelabel.config';

@Component({
  components: {
    LogoAlt,
    FacebookIcon,
    TwitterIcon,
    LinkedInIcon,
    InstagramIcon,
  },
})

export default class LandingFooter extends Vue {
  email = email;
  phone = phone;
}
